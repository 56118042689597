.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;

  &_picture {
    border-radius: 50%;
    border: 0.4rem solid rgb(230, 230, 230);
    width: 200px;
    height: 200px;
    margin: 1rem;
    /* box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.8); */
  }

  &_name {
    font-size: 1.5rem;
    font-weight: bold;
  }

  &_role {
    font-size: 1.2rem;
  }
}
