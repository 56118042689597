.navbar {
  padding: 0;
  background-color: $primary-color;
  display: flex;
  flex-direction: column;
}

.navbar-brand, .navbar-toggler {
  padding: .7rem;
}

.navbar-nav {
  margin: -0.5rem -1rem;
  width: calc(100% + 2rem);

  .nav-item {
    padding: .2rem 2rem;

    &.active {
      background-color: lighten($primary-color, 5%) !important;
    }
  }

  @media (min-width: 992px) {
    display: flex;
    align-items: center;
    margin: 0;
    width: auto;

    .nav-item {
      padding: .5rem;
    }
  }

  &--sub {
    box-shadow: none;
    margin: 0;

    .nav-item {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 1.8rem;
      background-color: lighten($primary-color, 5%);

      &.active {
        background-color: lighten($primary-color, 10%) !important;
      }
    }

    @media (min-width: 992px) {
      display: none;

      .nav-item {
        padding-left: 0;
      }
    }
  }

  &--desktop {
    display: none;

    @media (min-width: 992px) {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .nav-item {
      padding: 0;
      text-align: center;
      width: 240px;
      background-color: transparent;
    }
  }
}

.sub-navigation {
  width: calc(100% + 2rem);
  margin-bottom: -0.5rem;
  margin-left: -2rem;
  margin-right: -2rem;
  background-color: lighten($primary-color, 5%);
}
