// CARDS BASIC
.card {
    border: 0;
    .card-title a {
        color: #424242;
        @include transition (0.4s);
        &:hover {
            color: #616161;
            @include transition (0.4s);
        }
    }
    img {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        @extend .img-fluid;
    }
    .card-block {
        position:relative;
    }
    .card-footer {
        padding-bottom: 0;
        font-size:1.2rem;
        &:after {
            display:block;
            content: "";
            clear:both;
        }
        .card-link {
            color: inherit;
        }
    }
}

// Jumbtotron
.jumbotron {
    background-color: #fff;
    padding: 2rem;
    &.m-1,
    &.m-2,
    &.m-3 {
        z-index: 50;
        position: relative;
        margin-left: 3%;
        margin-right: 3%;
    }
    &.m-1 {
        margin-top: -20px;
    }
    &.m-2 {
        margin-top: -30px;
    }
    &.m-3 {
        margin-top: -40px;
    }
}

// .list-group fix
.list-group-item {
    width:100%;
}

// edge header
.edge-header {
    display:block;
    height:278px;
    background-color:#ccc;
}

.free-bird {
    margin-top:-100px; 
}

// Form sets

.card .form-header {
    color: #fff;
    text-align: center;
    margin-top: -50px;
    margin-bottom: 3rem;
    padding: 1rem;
    @extend .z-depth-1-half;
    @include border-radius(2px);
    h3 {
        margin: 0;
        padding: 0.7rem;
    }
}
.call .fa {
    margin-right: 5px;
}