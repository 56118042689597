@import "bootstrap-mdb";
@import "mdb";
@import "components/navbar";
@import "components/card";
@import "components/profile";
@import "pages/residency";

body {
  /*padding-top: 50px;*/
  padding-top: 58px; /* For navbar */
}

#background-image {
  display: block;
  position: fixed;
  top: 0;
  left: auto;
  right: auto;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url("../images/fond0.jpg");
  background-color: rgb(50, 55, 65);
  background-size: cover;
}

.navbar {
  border-radius: 0;
}

.navbar-collapse.collapse.in {
  display: block!important;
}

#main {
  padding-top: 15px;
}


.panel {
  text-align: center;
}


/*** HEADER ***/

#header {
  color: white;
  margin-bottom: 20px;

  background-color: #333333;
  opacity: 0.90;

  vertical-align: center;
  text-align: center;
}

#header .logo_header {
  padding: 20px;
  height: 100px;
}

#header h1 {
  font-size: 2em;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  margin-top: 15px;
  padding: 20px 10px;
}

#header h1.subtitle {
  font-size: 1.5em;
  margin-top: 0;
}

.caption {
  border-top: 1px solid rgba(0,0,0,.1);
  margin-top: 5px;
  padding-top: 5px;
}

p.footnote {
  margin-top: 0;
  margin-bottom: 8px;
  padding: 0 44px;
  font-style: italic;
}

footer.page-footer .footer-copyright {
  height: auto;
  line-height: inherit;
  padding-top: 10px;
  padding-bottom: 20px;
  font-weight: 200;
  font-family: 'Raleway', sans-serif;
}

.footer-copyright p {
  margin-bottom: 0;
  font-weight: normal;
}


/****** CATALOGUE *** */

.catalogue_list {
  margin-top: 25px;
  margin-bottom: 25px;
}

.catalogue_flex {
}

.catalogue_list .card {
  max-width: 300px;
  min-height: 355px;
  margin: 10px 0;
}

.catalogue_list .card-block {
  display: flex;
  align-items: center;
}

.catalogue_list .card .view {
  height: 200px;
}

.catalogue_list .event-image {
  background-size: cover;
  height: 200px;
  width: 100%;
}

.catalogue_list .event-image--centered {
  background-position: center center;
}

.catalogue_list .card-block {
  vertical-align: middle;
  font-weight: 400;
  font-family: 'Raleway', sans-serif;
}

.catalogue_list > .row > div {
  margin-bottom: 40px;
}

@media screen and (max-width: 767px) {
  .catalogue_list .card {
    min-width: 100%;
    max-width: 100%;
    min-height: auto;
  }
}


/***** TALK: MAIN ****/

.event > .row {
  margin-bottom: 25px;
}

.event-section__title {
  margin: 30px 0;

  font-weight: 400;
  text-shadow: 1px 1px 1px rgb(0, 0, 0);
  color: white;
}


/***** TALK: Take outs section */
.takeout-panel {
  margin-top: 15px;
}

.takeout-column {
  margin-bottom: 30px;
}

.takeout-media i {
  font-size: 75px;
}


/***** TALK: REFERENCES LIST ****/

.event_references > .row > div {
  margin-bottom: 30px;
}

.event_references .card {
  width: 30%;
  margin: 10px;
}

.event_reference {
  display: flex;
  align-items: center;
  padding: 0 5px;
  padding-top: 8px;
  margin: 0 5px;
  margin-bottom: 8px;
}

.event_reference:not(:first-of-type) {
  border-top: 1px solid rgba(0,0,0,.1);
}

.event_reference i {
  width: 20px;
  margin-right: 15px;
}

.event_reference i {
  width: 20px;
  margin-right: 15px;
}

@media screen and (max-width: 991px) {
  .event_references .card {
    width: 45%;
  }
}

@media screen and (max-width: 767px) {
  .event_references .card {
    width: 100%;
  }
}

/***** TALK Moved ****/

.event_moved_to i {
  margin: 10px;
  padding: 10px;
  font-size: 140%;
  background-color: #3E4551;
  color: white;
}

.event_moved_to a {
  font-size: 130%;
}


/***** APPEARANCES ****/


.appearances__title {
  font-weight: 400;
  text-shadow: 1px 1px 1px rgb(0, 0, 0);
  color: white;
}

.appearances__title:not(:first-of-type) {
  margin: 30px 0;
}

.appearances {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: left;
  padding: 0 5px;
  padding-top: 8px;
  margin: 0 5px;
  margin-bottom: 30px;
}

.appearances .card {
  /*height: 100%;*/
  margin-bottom: 10px;
}

.appearance {
  align-items: center;
  padding: 5px 10px;
  margin: 5px;
}

.appearance h4 {
  font-size: 1.35em;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  margin: 0;
}

.appearance p {
  font-style: italic;
  font-size: 1em;
  font-family: 'Raleway', sans-serif;
  font-weight: 300;
  margin: 0;
  margin-top: 5px;
}

.appearance p:not(:first-of-type) {
  margin: 0;
}

p.appearance_talk {
  font-style: normal;
}

.appearance_talk:before {
  content:'\25BA  ';
  font-size: 0.65em;
}

.apprendre_pub {
  background-color: #33b5e5;
  color: white;
}

.apprendre_pub p {
  font-style: normal;
  font-weight: 400;
}

.apprendre_pub__action {
  text-align: center;
}

.apprendre_pub .btn {
  margin-top: 10px;
}
