.card-content {
  position: relative;
  display: flex;
  align-items: center;

  &_description {
    width: 100%;
  }

  &_aside {
    flex: 1 0 auto;
    margin: 0 1rem;
    padding: 1rem;

    &--onlylargescreen {
      @media (max-width: $medium-screen-up) {
        display: none;
        background-color: red;
      }
    }
  }

  &_image {
    width: 400px;
    max-width: 100%;
    max-width: 100%;

    &--large {
      width: 600px;
    }
  }

  &--right {
    flex-direction: row-reverse;
  }

  &--center {
    justify-content: center;

    h3 {
      align-self: flex-start;
    }
  }

  @media (max-width: $medium-screen-up) {
    flex-direction: column;
  }
}
