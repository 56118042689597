.residency-join {
  display: flex;
  justify-content: space-between;

  @media (max-width: $small-screen-up) {
    flex-direction: column;
  }
}

.residency-staff {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: $medium-screen-up) {
    flex-direction: column;
    justify-content: center;
  }
}
